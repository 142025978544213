
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getLang } from "./i18n/config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBUpw6CHK4DOMIXuZ7GkF77r9lrECukzAY",
    authDomain: "neutrinodownload.firebaseapp.com",
    projectId: "neutrinodownload",
    storageBucket: "neutrinodownload.appspot.com",
    messagingSenderId: "553141850004",
    appId: "1:553141850004:web:8a25b2150b464f7ef8c981",
    measurementId: "G-54HQ7G79MN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

logEvent(analytics, 'app_open', {
    'lang': getLang(),
});

export const logBtnDownloadApk = () => logEvent(analytics, 'btn_download_apk');

export const logBtnContactUs = () => logEvent(analytics, 'btn_contact_us');

export default analytics;