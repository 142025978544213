import './App.css';
import Application from './widgets/Application';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import './i18n/config';
import { getDirection } from './i18n/config';

const theme = createTheme({
  direction: getDirection(),
});

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ThemeProvider theme={theme}>
          <Application></Application>
        </ThemeProvider>
      </header>
    </div>
  );
}

export default App;
