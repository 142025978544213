
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const DEFAULT_LANG = 'en';
const SUPPORTED_LANGS = [DEFAULT_LANG, 'ru', 'hi', 'es', 'tr', 'ar', 'fa', 'uk'];
const RTL_LANGUAGES = ['ar', 'fa'];

function getBrowserLocales(options = {}) {
    const defaultOptions = {
        languageCodeOnly: false,
    };
    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
    if (!browserLocales) {
        return undefined;
    }
    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
}

export function getLang() {
    try {
        const locales = getBrowserLocales()
        if (locales == undefined) return DEFAULT_LANG;
        if (locales.length >= 1) {
            var lang = locales[0].toLowerCase();
            if (lang.length > 2) {
                lang = lang.substring(0, 2);
            }
            if ((new Set(SUPPORTED_LANGS)).has(lang)) {
                return lang;
            }
            return DEFAULT_LANG;
        }
    } catch (e) { }
    return DEFAULT_LANG;
}

export const isRtl = (new Set(RTL_LANGUAGES)).has(getLang() as string);

if (isRtl) {
    document.dir = 'rtl'
    document.documentElement.lang = getLang();
}

export const getDirection = () => isRtl ? 'rtl' : 'ltr';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: require('./locales/en/translations.json') },
            ru: { translation: require('./locales/ru/translations.json') },
            es: { translation: require('./locales/es/translations.json') },
            hi: { translation: require('./locales/hi/translations.json') },
            tr: { translation: require('./locales/tr/translations.json') },
            ar: { translation: require('./locales/ar/translations.json') },
            fa: { translation: require('./locales/fa/translations.json') },
            uk: { translation: require('./locales/uk/translations.json') },
        },
        lng: getLang(),
        fallbackLng: DEFAULT_LANG,
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

    });

i18n.languages = SUPPORTED_LANGS;

export default i18n;