import { Box, Button, Card, createTheme, Rating, ThemeProvider, Typography } from '@mui/material';
import Const from '../Const';
import TelegramIcon from '@mui/icons-material/Telegram';
import GetAppIcon from '@mui/icons-material/GetApp';
import VerifiedIcon from '@mui/icons-material/Verified';
import { green, grey, blue } from '@mui/material/colors';
import Rive from '@rive-app/react-canvas';
import { useTranslation } from 'react-i18next';
import { logBtnContactUs, logBtnDownloadApk } from '../analytics';

type ChildrenType = JSX.Element | JSX.Element[] | string | string[];

const AppName = () => {
    const { t } = useTranslation();
    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }}>
        <Typography variant='h4' sx={{ fontWeight: 'bold' }}>{t('app_name')}</Typography>
        <Box sx={{ width: '8px' }} />
        <VerifiedIcon sx={{ color: blue[500], width: '36px', height: '36px', }} />
    </Box>;
}

const AppDescription = () => {
    const { t } = useTranslation();
    { t('app_title') }
    return <Typography variant='h6'>{t('app_title')}</Typography>;
}


function AppCounters() {
    const Title = ({ children }: { children: ChildrenType }) =>
        <Typography variant='subtitle2' sx={{ fontWeight: 'bold', textTransform: "uppercase" }}>{children}</Typography>;

    const Value = ({ children }: { children: ChildrenType }) =>
        <Typography variant='body1' >{children}</Typography>;

    const Item = ({ children }: { children: ChildrenType }) =>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>{children}</Box>;
    const { t } = useTranslation();
    return <Box
        sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
        }}>
        <Item>
            <Title>{t('stats.installs.title')}</Title>
            <Box sx={{ height: '4px' }} />
            <Value>1.2M</Value>
        </Item>
        <Item>
            <Title>{t('stats.size.title')}</Title>
            <Box sx={{ height: '4px' }} />
            <Value>24.9mb</Value>
        </Item>
        <Item>
            <Title>{t('stats.version.title')}</Title>
            <Box sx={{ height: '4px' }} />
            <Value >7.0.4</Value>
        </Item>

    </Box>;
}

export const AppLogoAnimation = () => (
    <Box
        sx={{
            height: 230,
            width: 230,
        }}>
        <Rive src="logo.riv" />
    </Box>
);

const greenTheme = createTheme({ palette: { primary: green } })

const DownloadButtonRecommended = ({ href, children }: { href: string, children?: ChildrenType }) =>
    <ThemeProvider theme={greenTheme}>
        <Button
            size='large'
            variant="contained"
            href={href}
            startIcon={<GetAppIcon />}
            disableElevation={true}
            onClick={logBtnDownloadApk}
            sx={{
                width: '100%',
                color: '#ffffff',
            }}>
            {children}
        </Button>
    </ThemeProvider>;

const DownloadButton = () => {
    const { t } = useTranslation();
    return <ThemeProvider theme={greenTheme}>
        <Button
            size='large'
            variant="outlined"
            href={Const.DOWNLOAD_APK_ARM64_URL}
            startIcon={<GetAppIcon />}
            onClick={logBtnDownloadApk}
            disableElevation={true}
            sx={{
                width: '100%',
                color: green,
            }}>
            {t('btn.download_64')}
        </Button>
    </ThemeProvider>;
}


const ContactUsButton = () => {
    const { t } = useTranslation();
    return <Button
        size='small'
        variant="contained"
        href={Const.TG_MAIN_CHANNEL}
        startIcon={<TelegramIcon />}
        onClick={logBtnContactUs}
        disableElevation={true}
        target="_blank">{t('btn.contact_us')}</Button>;
}

function Application() {
    const { t } = useTranslation();
    return (
        <Card
            sx={{
                maxWidth: 300,
                paddingTop: '24px',
                paddingLeft: '24px',
                paddingRight: '24px',
                paddingBottom: '24px',
                marginTop: '24px',
                marginLeft: '24px',
                marginRight: '24px',
                marginBottom: '24px',
            }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '24px',
                        paddingTop: '0px',
                        alignItems: 'center',
                    }}>
                    <AppName />
                    <Box sx={{ height: '8px' }} />
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyItems: 'center',
                    }}>
                        <Rating name="app-rating" value={5} />
                        <Box sx={{ width: '8px' }} />
                        <Typography variant='caption' sx={{ marginTop: '3.1px', color: grey[500] }} >{t('app_reviews.title')}</Typography>
                    </Box>
                    <Box sx={{ height: '16px' }} />
                    <AppLogoAnimation />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingTop: '24px',
                        }}>
                        <AppDescription />
                    </Box>
                </Box>

                <DownloadButtonRecommended href={Const.DOWNLOAD_APK_URL}>{t('btn.download_32')}</DownloadButtonRecommended>
                <Box sx={{ height: '8px' }} />
                <DownloadButton />

                <Box sx={{ height: '24px' }} />

                <AppCounters />

                <Box sx={{ height: '24px' }} />

                <ContactUsButton />
                <Box sx={{ height: '16px' }} />
                <Typography variant='caption' color={grey[500]}>
                    {t('btn.contact_us.footer')}
                </Typography>
            </Box>
        </Card>
    );
}

export default Application;
